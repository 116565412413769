import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import React, { Suspense, lazy } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import theme from './theme';
import CreditScoreDetails from './containers/CreditScore/CreditScoreDetails';

const Login = lazy(() => import('./containers/Auth/Login'));
const Dashboard = lazy(() => import('./containers/Dashboard/index'));
const Layout = lazy(() => import('./containers/Layout/index'));
const Users = lazy(() => import('./containers/Users/index'));
const UserDeatils = lazy(() => import('./containers/Users/UserDetails'));
const Notifications = lazy(() => import('./containers/Notifiactions/index'));
const CreditScore = lazy(() => import('./containers/CreditScore/index'));

const isLoggedIn = () => {
	let token = localStorage.getItem('token');
	return token;
};

const PublicRoutes = (props: any): any => {
	const { children } = props;
	if (isLoggedIn()) return <Navigate replace to="/admin/dashboard" />;

	return children;
};

const PrivateRoutes = (props: any): any => {
	const { children } = props;
	if (isLoggedIn()) return <Layout>{children}</Layout>;

	return <Navigate replace to="/admin" />;
};

function App() {
	return (
		<div>
			<ThemeProvider theme={theme}>
				<Suspense
					fallback={
						<div className="intial-loader">
							<CircularProgress />
						</div>
						// <div className="lds-ring">
						// 	<div />
						// 	<div />
						// 	<div />
						// 	<div />
						// </div>
					}
				>
					<Routes>
						<Route path="*" element={<Navigate to="/admin" replace />} />
						<Route
							path="/admin"
							element={
								<PublicRoutes>
									<Login />
								</PublicRoutes>
							}
						/>

						<Route
							path="/admin/dashboard"
							element={
								<PrivateRoutes>
									<Dashboard />
								</PrivateRoutes>
							}
						/>
						<Route
							path="/admin/users"
							element={
								<PrivateRoutes>
									<Users />
								</PrivateRoutes>
							}
						/>
						<Route
							path="/admin/users/:id"
							element={
								<PrivateRoutes>
									<UserDeatils />
								</PrivateRoutes>
							}
						/>
						<Route
							path="/admin/notifications"
							element={
								<PrivateRoutes>
									<Notifications />
								</PrivateRoutes>
							}
						/>
						<Route
							path="/admin/credit-score"
							element={
								<PrivateRoutes>
									<CreditScore />
								</PrivateRoutes>
							}
						/>
						<Route
							path="/admin/credit-history/:id"
							element={
								<PrivateRoutes>
									<CreditScoreDetails />
								</PrivateRoutes>
							}
						/>
					</Routes>
				</Suspense>
			</ThemeProvider>
		</div>
	);
}

export default App;
