import { createSlice } from '@reduxjs/toolkit';
import { userLoginAsync } from './authAPI';

export interface AuthState {
	value: number;
	isLoading: boolean;
}

const initialState: AuthState = {
	value: 0,
	isLoading: false,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: (builder) => {
		builder
			.addCase(userLoginAsync.pending, (state: AuthState) => {
				state.isLoading = true;
			})
			.addCase(userLoginAsync.fulfilled, (state, { payload }) => {
				localStorage.setItem('token', payload.accessToken);
				localStorage.setItem('user-info', JSON.stringify(payload.data));
				state.isLoading = false;
			})
			.addCase(userLoginAsync.rejected, (state) => {
				state.isLoading = false;
			});
	},
});

// export const { } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default authSlice.reducer;
