import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import './assets/styles/index.scss';
import App from './Routes';
import 'rsuite/dist/rsuite.min.css';
import { store } from './redux/store';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Provider store={store}>
				<App />
				<Toaster
					position="top-right"
					reverseOrder={false}
					gutter={8}
					containerClassName=""
					containerStyle={{}}
					toastOptions={{
						// Define default options
						className: '',
						duration: 5000,
						style: {
							background: '#363636',
							color: '#fff',
						},

						// Default options for specific types
						// success: {
						// 	duration: 3000,
						// 	theme: {
						// 		primary: 'green',
						// 		secondary: 'black',
						// 	},
						// },
						error: {
							style: {
								// background: 'red',
								fontSize: 16,
							},
						},
					}}
				/>
			</Provider>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
