import { createTheme } from '@mui/material';

const theme = createTheme({
	palette: {
		primary: {
			main: '#1DBBA0',
		},
		secondary: {
			main: '#1DBBA0',
		},
	},
});

export default theme;
