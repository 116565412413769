import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiPost } from '../../utils/index';

export const userLoginAsync = createAsyncThunk(
	'auth/login',
	async (data: any, { rejectWithValue }) => {
		try {
			const response: any = await apiPost('/api/v1/auth/admin/login', data);
			return response;
		} catch (err: any) {
			return rejectWithValue(err);
		}
	}
);
