import { createSlice } from '@reduxjs/toolkit';
import {
	getUserCountAsync,
	getUserStatusAsync,
	getBudgetStatisticAsync,
} from './dashboardAPI';

export interface State {
	userCountLoading: boolean;
	userStatusLoading: boolean;
	budgetStatisticLoading: boolean;
	userCount: any;
	userStatus: any;
	budgetStatics: any;
}

const initialState: State = {
	userCountLoading: false,
	userStatusLoading: false,
	budgetStatisticLoading: false,
	userCount: {},
	userStatus: [],
	budgetStatics: [],
};

export const dashboardSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getUserCountAsync.pending, (state: State) => {
				return { ...state, userCountLoading: true };
			})
			.addCase(getUserCountAsync.fulfilled, (state, { payload }) => {
				return {
					...state,
					userCountLoading: false,
					userCount: payload?.data || {},
				};
			})
			.addCase(getUserCountAsync.rejected, (state) => {
				return { ...state, userCountLoading: false };
			})
			.addCase(getUserStatusAsync.pending, (state: State) => {
				return { ...state, userStatusLoading: true };
			})
			.addCase(getUserStatusAsync.fulfilled, (state, { payload }) => {
				return {
					...state,
					userStatusLoading: false,
					userStatus: payload?.data || {},
				};
			})
			.addCase(getUserStatusAsync.rejected, (state) => {
				return { ...state, userStatusLoading: false };
			})
			.addCase(getBudgetStatisticAsync.pending, (state: State) => {
				return { ...state, budgetStatisticLoading: true };
			})
			.addCase(getBudgetStatisticAsync.fulfilled, (state, { payload }) => {
				return {
					...state,
					budgetStatisticLoading: false,
					budgetStatics: payload?.data || {},
				};
			})
			.addCase(getBudgetStatisticAsync.rejected, (state) => {
				return { ...state, budgetStatisticLoading: false };
			});
	},
});

export default dashboardSlice.reducer;
