import { createSlice } from '@reduxjs/toolkit';
import {
	createNotificationAsync,
	getAllNotificationAsync,
	notificationDeleteAsync,
} from './notificationAPI';

export interface State {
	isLoading: boolean;
	isCreating: boolean;
	allNotification: any;
	notificationDeleteLoading: boolean;
}

const initialState: State = {
	isLoading: false,
	isCreating: false,
	allNotification: {},
	notificationDeleteLoading: false,
};

export const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(createNotificationAsync.pending, (state: State) => {
				state.isCreating = true;
			})
			.addCase(createNotificationAsync.fulfilled, (state) => {
				state.isCreating = false;
			})
			.addCase(createNotificationAsync.rejected, (state) => {
				state.isCreating = false;
			})
			.addCase(getAllNotificationAsync.pending, (state: State) => {
				return {
					...state,
					isLoading: true,
				};
			})
			.addCase(getAllNotificationAsync.fulfilled, (state, { payload }) => {
				return {
					...state,
					isLoading: false,
					allNotification: payload.data || {},
				};
			})
			.addCase(getAllNotificationAsync.rejected, (state) => {
				return {
					...state,
					isLoading: false,
				};
			})
			.addCase(notificationDeleteAsync.pending, (state: State) => {
				return { ...state, userDeleteLoading: true };
			})
			.addCase(notificationDeleteAsync.fulfilled, (state) => {
				return { ...state, userDeleteLoading: false };
			})
			.addCase(notificationDeleteAsync.rejected, (state) => {
				return { ...state, userDeleteLoading: false };
			});
	},
});

export default notificationSlice.reducer;
