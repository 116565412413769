import { createSlice } from '@reduxjs/toolkit';
// import toast from 'react-hot-toast';
// import { RootState, AppThunk } from '../../redux/store';
import {
	userTableListAsync,
	userDetailsAsync,
	userDeleteAsync,
	userCsvExportAsync,
} from './userAPI';

export interface UsersState {
	users: any;
	isLoading: boolean;
	userDetails: any;
	userDeleteLoading: any;
	userCsvExportLoading: any;
}

const initialState: UsersState = {
	users: {},
	isLoading: false,
	userDetails: {},
	userDeleteLoading: false,
	userCsvExportLoading: false,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const userSlice = createSlice({
	name: 'users',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: (builder) => {
		builder
			.addCase(userTableListAsync.pending, (state: UsersState) => {
				return { ...state, isLoading: true };
			})
			.addCase(userTableListAsync.fulfilled, (state, { payload }) => {
				return { ...state, isLoading: false, users: payload.data };
			})
			.addCase(userTableListAsync.rejected, (state) => {
				return { ...state, isLoading: false };
			})
			.addCase(userDetailsAsync.pending, (state: UsersState) => {
				return { ...state, isLoading: true };
			})
			.addCase(userDetailsAsync.fulfilled, (state, { payload }) => {
				return { ...state, isLoading: false, userDetails: payload.data };
			})
			.addCase(userDetailsAsync.rejected, (state) => {
				return { ...state, isLoading: false };
			})
			.addCase(userDeleteAsync.pending, (state: UsersState) => {
				return { ...state, userDeleteLoading: true };
			})
			.addCase(userDeleteAsync.fulfilled, (state) => {
				return { ...state, userDeleteLoading: false };
			})
			.addCase(userDeleteAsync.rejected, (state) => {
				return { ...state, userDeleteLoading: false };
			})
			.addCase(userCsvExportAsync.pending, (state: UsersState) => {
				return { ...state, userCsvExportLoading: true };
			})
			.addCase(userCsvExportAsync.fulfilled, (state) => {
				return { ...state, userCsvExportLoading: false };
			})
			.addCase(userCsvExportAsync.rejected, (state) => {
				return { ...state, userCsvExportLoading: false };
			});
	},
});

// export const { } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default userSlice.reducer;
