import { createSlice } from '@reduxjs/toolkit';
import {
	creditScoreDetailsAsync,
	credituserTableListAsync,
	getStateWiseScores,
} from './creditApi';

export interface UsersState {
	users: any;
	isLoading: boolean;
	stateWiseScores: object[];
	creditDetails: any;
}

const initialState: UsersState = {
	users: [],
	isLoading: false,
	stateWiseScores: [],
	creditDetails: {},
};

export const creditUserSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(credituserTableListAsync.pending, (state: UsersState) => {
				return { ...state, isLoading: true };
			})
			.addCase(credituserTableListAsync.fulfilled, (state, { payload }) => {
				return { ...state, isLoading: false, users: payload.data };
			})
			.addCase(credituserTableListAsync.rejected, (state) => {
				return { ...state, isLoading: false };
			});

		// get state wise scores

		builder.addCase(getStateWiseScores.fulfilled, (state, { payload }) => {
			return { ...state, stateWiseScores: payload.data };
		});

		builder
			.addCase(creditScoreDetailsAsync.pending, (state: UsersState) => {
				return { ...state, isLoading: true };
			})
			.addCase(creditScoreDetailsAsync.fulfilled, (state, { payload }) => {
				return { ...state, isLoading: false, creditDetails: payload.data };
			})
			.addCase(creditScoreDetailsAsync.rejected, (state) => {
				return { ...state, isLoading: false };
			});

		// .addCase(getStateWiseScores.rejected, (state, action) => {
		// 	return { ...state, isLoading: false };
		// });
	},
});

export default creditUserSlice.reducer;
