import moment from 'moment';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiDelete, apiGet, apiPost } from '../../utils/index';

export const createNotificationAsync = createAsyncThunk(
	'notification/createNotification',
	async (data: any, thunkAPI) => {
		try {
			const response: any = await apiPost(`/api/v1/admin/notification`, data);
			return response;
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err);
		}
	}
);

export const getAllNotificationAsync = createAsyncThunk(
	'notification/getAllNotification',
	async (data: any, thunkAPI) => {
		try {
			let {
				page,
				size,
				startDate,
				endDate,
				platform,
				title,
				status,
				sortingKey,
				sortingOrder,
			} = data;

			startDate = moment(startDate).unix();
			endDate = moment(endDate).unix();

			const response: any = await apiGet(
				`/api/v1/admin/notification?page=${page}&size=${size}&platform=${platform}&title=${title}&status=${status}&startDate=${startDate}&endDate=${endDate}&sortingKey=${sortingKey}&sortingOrder=${sortingOrder}`
			);
			return response;
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err);
		}
	}
);

export const notificationDeleteAsync = createAsyncThunk(
	'user/delete',
	async (id: any, thunkAPI) => {
		try {
			const response: any = await apiDelete(`/api/v1/admin/notification/${id}`);
			return response;
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err);
		}
	}
);
