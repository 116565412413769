import React, { useEffect } from 'react';
import { Grid, CircularProgress, Card, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import leftArrow from '../../assets/images/arrow-small-left.svg';
import { creditScoreDetailsAsync } from './creditApi';
import { getScoreColour } from '.';

function CreditScoreDetails() {
	const navigate = useNavigate();
	const params = useParams();
	const dispatch = useAppDispatch();

	const { isLoading = false, creditDetails = {} } = useAppSelector(
		(state) => state.creditScore
	);

	useEffect(() => {
		const { id = '' } = params;
		dispatch(creditScoreDetailsAsync(id));
	}, []);

	// let fullName = useMemo(() => {
	// 	let {
	// 		firstName = '',
	// 		middleName = '',
	// 		lastName = '',
	// 	} = creditDetails || {};
	// 	return firstName + ' ' + middleName + ' ' + lastName;
	// }, [creditDetails]);

	if (isLoading) {
		return (
			<div className="intial-loader">
				<CircularProgress />
			</div>
		);
	}

	return (
		<div className="user-details">
			<Grid container>
				<Grid item xs={12}>
					<h2>
						<img
							src={leftArrow}
							alt="back-arrow"
							onClick={() => navigate(-1)}
						/>
						<p>Credit Score History </p>
					</h2>
				</Grid>

				<Grid item xs={12} className="cus-mt-20">
					<Grid container>
						{creditDetails?.creditScoreHistory?.map((score: any) => {
							return (
								<Grid item xs={12} md={6} lg={4}>
									<Card className="credit-score-listing" elevation={1}>
										<Grid container>
											<Grid item xs={6}>
												<Box>
													<h5>Date:</h5>
													<p>{score?.date || '-'} </p>
												</Box>
											</Grid>
											<Grid item xs={6}>
												<Box>
													<h5>Score:</h5>
													<p
														style={{
															color: getScoreColour(score?.score) || '#dadada',
															fontWeight: 700,
														}}
													>
														{score?.score || '-'}{' '}
													</p>
												</Box>
											</Grid>
										</Grid>
									</Card>
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}
export default CreditScoreDetails;
