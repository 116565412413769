import React from 'react';
import ContentLoader from 'react-content-loader';

const TableLoaderStyle = {
	padding: 16,
	minHeight: 400,
};

function TableContentLoader(props: any) {
	return (
		<div style={TableLoaderStyle}>
			<ContentLoader
				speed={2}
				width="100%"
				height={400}
				// viewBox="0 0 100% 600"
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				{...props}
			>
				<rect x="0" y="0" width="100%" height="32" />
				<rect x="0" y="50" width="100%" height="32" />
				<rect x="0" y="100" width="100%" height="32" />
				<rect x="0" y="150" width="100%" height="32" />
				<rect x="0" y="200" width="100%" height="32" />
				<rect x="0" y="250" width="100%" height="32" />
				<rect x="0" y="300" width="100%" height="32" />
				<rect x="0" y="350" width="100%" height="32" />
			</ContentLoader>
		</div>
	);
}

export default TableContentLoader;
