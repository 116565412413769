import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiDelete, apiGet } from '../../utils/index';

export const userTableListAsync = createAsyncThunk(
	'user/tableList',
	async (data: any, thunkAPI) => {
		try {
			const {
				page,
				size,
				startDate,
				endDate,
				creditScore,
				lastLogin,
				email,
				sortingKey,
				sortingOrder,
				budget,
			} = data;
			const response: any = await apiGet(
				`/api/v1/admin/user?page=${page}&size=${size}&startDate=${startDate}&endDate=${endDate}&creditScore=${creditScore}&lastLogin=${lastLogin}&email=${email}&sortingKey=${sortingKey}&sortingOrder=${sortingOrder}&isBudgetSet=${budget}`
			);
			return response;
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err);
		}
	}
);

export const userDetailsAsync = createAsyncThunk(
	'user/details',
	async (id: any, thunkAPI) => {
		try {
			const response: any = await apiGet(`/api/v1/admin/user/${id}`);
			return response;
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err);
		}
	}
);

export const userDeleteAsync = createAsyncThunk(
	'user/delete',
	async (id: any, thunkAPI) => {
		try {
			const response: any = await apiDelete(`/api/v1/admin/user/${id}`);
			return response;
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err);
		}
	}
);

export const userCsvExportAsync = createAsyncThunk(
	'user/userCsvExport',
	async (data: any, thunkAPI) => {
		try {
			const { startDate, endDate, creditScore, lastLogin, email, budget } =
				data;
			const response: any = await apiGet(
				`/api/v1/admin/user/exports?startDate=${startDate}&endDate=${endDate}&creditScore=${creditScore}&lastLogin=${lastLogin}&email=${email}&isBudgetSet=${budget}`
			);

			return response;
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err);
		}
	}
);
