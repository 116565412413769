import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiGet } from '../../utils/index';

export const credituserTableListAsync = createAsyncThunk(
	'credituser/tableList',
	async (data: any, thunkAPI) => {
		try {
			const {
				page,
				size,
				// startDate,
				// endDate,
				// creditScore,
				// lastLogin,
				// email,
				// sortingKey,
				// sortingOrder,
				// budget,
			} = data;
			// const response: any = await apiGet(
			// 	`/api/v1/admin/creditScoreUsers?page=${page}&size=${size}&startDate=${startDate}&endDate=${endDate}&creditScore=${creditScore}&lastLogin=${lastLogin}&email=${email}&sortingKey=${sortingKey}&sortingOrder=${sortingOrder}&isBudgetSet=${budget}`
			// );
			const response: any = await apiGet(
				`/api/v1/admin/creditScoreUsers?page=${page}&size=${size}`
			);
			return response;
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err);
		}
	}
);

export const getStateWiseScores = createAsyncThunk(
	'creditScore/state-wise-score',
	async (data: any, thunkAPI) => {
		try {
			const response: any = await apiGet(`/api/v1/admin/rangewiseCreditScore`);
			return response || [];
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err);
		}
	}
);

export const creditScoreDetailsAsync = createAsyncThunk(
	'credit-score/details',
	async (id: any, thunkAPI) => {
		try {
			const response: any = await apiGet(
				`/api/v1/admin/creditScoreHistory/${id}`
			);
			return response;
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err);
		}
	}
);
