export const API_METHOD_GET = 'get';
export const API_METHOD_POST = 'post';
export const API_METHOD_PUT = 'put';
export const API_METHOD_DELETE = 'delete';
export const USER_LOCAL_TOKEN = 'USER_LOCAL_TOKEN';
export const USER = 'USER';

/* Constants from env */

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const emailRegex = /^.+@.+\..+$/;
