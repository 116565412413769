import React from 'react';

function TableActionButton(props: any) {
	const {
		alt = '',
		className = '',
		onClick = () => {},
		icon = '',
		style = {},
	} = props;

	return (
		<div
			className={`action-button mr-4 ${className}`}
			onClick={onClick}
			style={{ ...style }}
		>
			<img src={icon} alt={alt} />
		</div>
	);
}

export default TableActionButton;
