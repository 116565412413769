import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../containers/Auth/authSlice';
import userReducer from '../containers/Users/userSlice';
import notificationReducer from '../containers/Notifiactions/notificationSlice';
import dashboardReducer from '../containers/Dashboard/dashboardSlice';
import creditScoreReducer from '../containers/CreditScore/creditScoreSlice';

export const store = configureStore({
	reducer: {
		auth: authReducer,
		users: userReducer,
		notification: notificationReducer,
		dashboard: dashboardReducer,
		creditScore: creditScoreReducer,
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
