import toast from 'react-hot-toast';

export default function useToast() {
	const success = (message: string) => {
		toast.success(message || 'Success', { duration: 3000 });
	};

	const error = (message: string) => {
		toast.error(message || 'Something went wrong', { duration: 3000 });
	};

	return { success, error };
}
