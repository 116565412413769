import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiGet } from '../../utils/index';

export const getUserCountAsync = createAsyncThunk(
	'dashboard/getUserCount',
	async (data: any, thunkAPI) => {
		try {
			const response: any = await apiGet(
				`/api/v1/admin/dashboard/countDetails`
			);
			return response;
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err);
		}
	}
);

export const getUserStatusAsync = createAsyncThunk(
	'dashboard/getUserStatus',
	async (days: any, thunkAPI) => {
		try {
			const response: any = await apiGet(
				`/api/v1/admin/dashboard/userStatus?filter=${days}`
			);
			return response;
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err);
		}
	}
);

export const getBudgetStatisticAsync = createAsyncThunk(
	'dashboard/getBudgetStatistic',
	async (days: any, thunkAPI) => {
		try {
			const response: any = await apiGet(
				`/api/v1/admin/dashboard/budgetStatistics?filter=${days}`
			);
			return response;
		} catch (err: any) {
			return thunkAPI.rejectWithValue(err);
		}
	}
);
