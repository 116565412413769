import axios from 'axios';
import moment from 'moment';
import {
	API_BASE_URL,
	API_METHOD_DELETE,
	API_METHOD_GET,
	API_METHOD_POST,
	API_METHOD_PUT,
} from './constant';

const axiosApiInstance = axios.create();
// const toast = useToast();
/*  API Request Methods */

export function generateUrl(path: any) {
	return API_BASE_URL + path;
}

export function getToken() {}

axiosApiInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		if (
			error.response?.status === 401 ||
			error?.response?.data?.message === 'Authorization token expired!'
		) {
			setTimeout(() => {
				localStorage.clear();
				window.location.assign('/');
			}, 1000);
		}
		return Promise.reject(error);
	}
);

export function apiRequest(endpoint: any, data: any, method: any) {
	return new Promise((resolve, reject) => {
		let token = window.localStorage.getItem('token');
		let headers = {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token || null}`,
		};

		if (method === API_METHOD_GET || method === API_METHOD_DELETE) {
			data = {
				params: data,
				headers,
			};
		}

		axiosApiInstance({
			method,
			url: endpoint,
			data,
			headers,
		})
			.then(({ data, status }: any) => {
				if (status === 200 || status === 201) {
					return resolve(data);
				}
				return reject(data);
			})
			.catch((err: any) => {
				return reject(err);
			});
	});
}

// API Post Request
export function apiPost(endPoint: string, data?: any) {
	return apiRequest(generateUrl(endPoint), data, API_METHOD_POST);
}

// API Delete Request
export function apiDelete(endPoint: string, data?: any) {
	return apiRequest(generateUrl(endPoint), data, API_METHOD_DELETE);
}

// API Get Request
export function apiGet(endPoint: string, data?: any) {
	return apiRequest(generateUrl(endPoint), data, API_METHOD_GET);
}

// API Put Request
export function apiPut(endPoint: string, data: any) {
	return apiRequest(generateUrl(endPoint), data, API_METHOD_PUT);
}

/*  Local Storage Methods */

export function setData(label: any, data: any) {
	if (window && window.localStorage) {
		localStorage.setItem(label, JSON.stringify(data));
	}
}

export function getData(label: any): any {
	if (window && window.localStorage) {
		let data: any = localStorage.getItem(label);
		return JSON.parse(data);
	}
	return null;
}

export function removeData(label: any) {
	if (window && window.localStorage) {
		localStorage.removeItem(label);
	}
}

export function capitalizeFirstLetter(string: any) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function removeLastLetter(string: any) {
	return string.slice(0, -1);
}

export function dateInFormat(date: any) {
	if (date) {
		return moment(date).format('DD/MM/YYYY');
	}
	return date;
}

export function dateInFormatWithTime(date: any) {
	if (date) {
		return moment(date).format('DD/MM/YYYY hh:mm:ss A');
	}
	return date;
}

export function getYearsDynamic(startYear = 2022) {
	let selectYear = startYear;
	let currentYear = new Date().getFullYear();
	const yearData = [];
	for (let i = selectYear; i <= currentYear; i++) {
		yearData.push({ label: i, value: i });
	}
	return yearData.reverse();
}

export const downloadFile = (fileLink: any, fileName = 'Users') => {
	return new Promise((resolve, reject) => {
		if (fileLink) {
			let link = document.createElement('a');
			link.href = fileLink;
			link.download = `${fileName}.csv`;
			link.click();
			return resolve(true);
		} else {
			reject(new Error('something bad happened'));
		}
	});
};
