import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import CircleIcon from '@mui/icons-material/Circle';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { Grid, Typography, Box } from '@mui/material';
import eyeIcon from '../../assets/images/eye.svg';
import TableActionButton from '../../components/TableActionButton';

import { credituserTableListAsync, getStateWiseScores } from './creditApi';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import useToast from '../../hooks/useToast';
import TableContentLoader from '../../components/TableContentLoader';

am4core.useTheme(am4themes_animated);

export const getScoreColour = (score: string) => {
	if (Number(score) <= 459) return '#AA2E28';
	if (Number(score) <= 660) return '#ED9141';
	if (Number(score) <= 734) return '#F8D73F';
	if (Number(score) <= 852) return '#8BB74A';
	if (Number(score) <= 1200) return '#348E49';
	return '#AA2E28';
};

function CreditScore() {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const navigate = useNavigate();
	const toast = useToast();
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		onApplyFilter({ page: newPage, size: rowsPerPage });
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
		onApplyFilter({ page: 0, size: +event.target.value });
	};

	const {
		isLoading = false,
		users: { rows = [], count = 0 } = {},
		stateWiseScores = [],
	} = useAppSelector((state) => state.creditScore);
	const dispatch = useAppDispatch();

	const getAllUser = (apiData: any) => {
		dispatch(credituserTableListAsync(apiData))
			.unwrap()
			.catch((error) => {
				toast.error(error?.response?.data?.message);
			});
	};
	// isApplyBtnClick: Boolean = false

	const onApplyFilter = (data: any) => {
		// let data = {
		// 	page: page,
		// 	size: rowsPerPage,
		// 	startDate:
		// 		loginFromTo && loginFromTo[0]
		// 			? moment(loginFromTo[0]).format('yyyy-MM-DD')
		// 			: null,
		// 	endDate:
		// 		loginFromTo && loginFromTo[1]
		// 			? moment(loginFromTo[1]).format('yyyy-MM-DD')
		// 			: null,
		// 	creditScore: creditScore,
		// 	lastLogin: loginSince ? moment(loginSince).format('yyyy-MM-DD') : null,
		// 	email: email,
		// 	sortingKey,
		// 	sortingOrder,
		// 	budget: budget,
		// 	sortingKey: null,
		// 	sortingOrder: null,
		// 	budget: null,
		// 	email: 'abc@gnail',
		// 	startDate: '2023-01-1',
		// 	endDate: '2023-05-1',
		// 	creditScore: '2',
		// 	lastLogin: null,
		// };
		getAllUser(data);
	};

	useEffect(() => {
		dispatch(getStateWiseScores({}));
		onApplyFilter({
			page: page,
			size: rowsPerPage,
		});
	}, []);

	let memoizeScores: any = useMemo(() => {
		let newData =
			stateWiseScores
				?.filter((score: any) => score.count > 0)
				?.map((score: any) => {
					return {
						...score,
						count: score?.count?.toString(),
						color: getScoreColour(score?.range?.split('-')[1]) || '#dadada',
						name: 'score',
					};
				}) || [];
		return newData;
	}, [stateWiseScores]);

	useEffect(() => {
		// Themes begin
		am4core.addLicense('CH285451766');
		am4core.useTheme(am4themes_animated);
		// Themes end
		var data = [
			{
				state: 'shimla',
				disabled: true,
				count: 2,
				color: am4core.color('#dadada'),
				opacity: 0.3,
				strokeDasharray: '4,4',
			},
			...memoizeScores,
		];

		// cointainer to hold both charts
		var container = am4core.create('chartdiv', am4core.Container);
		container.width = am4core.percent(100);
		container.height = am4core.percent(100);
		container.layout = 'horizontal';
		container.numberFormatter.numberFormat = '#';
		// container.integersOnly = true

		container.events.on('maxsizechanged', function () {
			chart1.zIndex = 0;
			separatorLine.zIndex = 1;
			dragText.zIndex = 2;
			chart2.zIndex = 3;
		});

		var chart1 = container.createChild(am4charts.PieChart);
		chart1.fontSize = 11;
		chart1.hiddenState.properties.opacity = 0; // this makes initial fade in effect
		chart1.data = data;
		chart1.radius = am4core.percent(70);
		chart1.innerRadius = am4core.percent(40);
		chart1.zIndex = 1;

		var series1: any = chart1.series.push(new am4charts.PieSeries());
		series1.dataFields.value = 'count';
		series1.dataFields.category = 'name';
		series1.colors.step = 2;
		series1.alignLabels = false;
		series1.labels.template.bent = true;
		series1.labels.template.radius = 3;
		series1.labels.template.padding(0, 0, 0, 0);
		// container.numberFormatter.numberFormat ="#,###|(#,###s)|'-'"

		var sliceTemplate1: any = series1.slices.template;
		sliceTemplate1.cornerRadius = 5;
		sliceTemplate1.draggable = true;
		sliceTemplate1.inert = true;
		sliceTemplate1.propertyFields.fill = 'color';
		sliceTemplate1.propertyFields.fillOpacity = 'opacity';
		sliceTemplate1.propertyFields.stroke = 'color';
		sliceTemplate1.propertyFields.strokeDasharray = 'strokeDasharray';
		sliceTemplate1.strokeWidth = 1;
		sliceTemplate1.strokeOpacity = 1;

		var zIndex = 5;

		sliceTemplate1.events.on('down', function (event: any) {
			event.target.toFront();
			// also put chart to front
			var series: any = event.target.dataItem.component;
			series.chart.zIndex = zIndex++;
		});

		series1.ticks.template.disabled = true;

		sliceTemplate1.states.getKey('active').properties.shiftRadius = 0;

		sliceTemplate1.events.on('dragstop', function (event: any) {
			handleDragStop(event);
		});

		// separator line and text
		var separatorLine = container.createChild(am4core.Line);
		separatorLine.x1 = 0;
		separatorLine.y2 = 300;
		separatorLine.strokeWidth = 3;
		separatorLine.stroke = am4core.color('#dadada');
		separatorLine.valign = 'middle';
		separatorLine.strokeDasharray = '5,5';

		var dragText = container.createChild(am4core.Label);
		dragText.text = 'Drag slices over the line';
		dragText.rotation = 90;
		dragText.valign = 'middle';
		dragText.align = 'center';
		dragText.paddingBottom = 5;

		// second chart
		var chart2 = container.createChild(am4charts.PieChart);
		chart2.hiddenState.properties.opacity = 0; // this makes initial fade in effect
		chart2.fontSize = 11;
		chart2.radius = am4core.percent(70);
		chart2.data = data;
		chart2.innerRadius = am4core.percent(40);
		chart2.zIndex = 1;

		var series2: any = chart2.series.push(new am4charts.PieSeries());
		series2.dataFields.value = 'count';
		series2.dataFields.category = 'name';
		series2.colors.step = 2;

		series2.alignLabels = false;
		series2.labels.template.bent = true;
		series2.labels.template.radius = 3;
		series2.labels.template.padding(0, 0, 0, 0);
		series2.labels.template.propertyFields.disabled = 'disabled';

		var sliceTemplate2 = series2.slices.template;
		sliceTemplate2.copyFrom(sliceTemplate1);

		series2.ticks.template.disabled = true;

		function handleDragStop(event: any) {
			var targetSlice = event.target;
			var dataItem1;
			var dataItem2;
			var slice1: any;
			var slice2: any;

			if (series1.slices.indexOf(targetSlice) != -1) {
				slice1 = targetSlice;
				slice2 = series2.dataItems.getIndex(targetSlice.dataItem.index).slice;
			} else if (series2.slices.indexOf(targetSlice) != -1) {
				slice1 = series1.dataItems.getIndex(targetSlice.dataItem.index).slice;
				slice2 = targetSlice;
			}

			dataItem1 = slice1.dataItem;
			dataItem2 = slice2.dataItem;

			var series1Center = am4core.utils.spritePointToSvg(
				{ x: 0, y: 0 },
				series1.slicesContainer
			);
			var series2Center = am4core.utils.spritePointToSvg(
				{ x: 0, y: 0 },
				series2.slicesContainer
			);

			var series1CenterConverted = am4core.utils.svgPointToSprite(
				series1Center,
				series2.slicesContainer
			);
			var series2CenterConverted = am4core.utils.svgPointToSprite(
				series2Center,
				series1.slicesContainer
			);

			// tooltipY and tooltipY are in the middle of the slice, so we use them to avoid extra calculations
			var targetSlicePoint = am4core.utils.spritePointToSvg(
				{ x: targetSlice.tooltipX, y: targetSlice.tooltipY },
				targetSlice
			);

			if (targetSlice == slice1) {
				if (targetSlicePoint.x > container.pixelWidth / 2) {
					// var value = dataItem1.value;

					dataItem1.hide();

					var animation = slice1.animate(
						[
							{ property: 'x', to: series2CenterConverted.x },
							{ property: 'y', to: series2CenterConverted.y },
						],
						400
					);
					animation.events.on('animationprogress', function () {
						slice1.hideTooltip();
					});

					slice2.x = 0;
					slice2.y = 0;

					dataItem2.show();
				} else {
					slice1.animate(
						[
							{ property: 'x', to: 0 },
							{ property: 'y', to: 0 },
						],
						400
					);
				}
			}
			if (targetSlice == slice2) {
				if (targetSlicePoint.x < container.pixelWidth / 2) {
					// var value = dataItem2.value;

					dataItem2.hide();

					var animation = slice2.animate(
						[
							{ property: 'x', to: series1CenterConverted.x },
							{ property: 'y', to: series1CenterConverted.y },
						],
						400
					);
					animation.events.on('animationprogress', function () {
						slice2.hideTooltip();
					});

					slice1.x = 0;
					slice1.y = 0;
					dataItem1.show();
				} else {
					slice2.animate(
						[
							{ property: 'x', to: 0 },
							{ property: 'y', to: 0 },
						],
						400
					);
				}
			}

			toggleDummySlice(series1);
			toggleDummySlice(series2);

			series1.hideTooltip();
			series2.hideTooltip();
		}

		function toggleDummySlice(series: any) {
			var show = true;
			for (var i = 1; i < series.dataItems.length; i++) {
				var dataItem = series.dataItems.getIndex(i);
				if (dataItem.slice.visible && !dataItem.slice.isHiding) {
					show = false;
				}
			}

			var dummySlice = series.dataItems.getIndex(0);
			if (show) {
				dummySlice.show();
			} else {
				dummySlice.hide();
			}
		}

		series2.events.on('datavalidated', function () {
			var dummyDataItem = series2.dataItems.getIndex(0);
			dummyDataItem.show(0);
			dummyDataItem.slice.draggable = false;
			dummyDataItem.slice.tooltipText = undefined;

			for (var i = 1; i < series2.dataItems.length; i++) {
				series2.dataItems.getIndex(i).hide(0);
			}
		});

		series1.events.on('datavalidated', function () {
			var dummyDataItem = series1.dataItems.getIndex(0);
			dummyDataItem.hide(0);
			dummyDataItem.slice.draggable = false;
			dummyDataItem.slice.tooltipText = undefined;
		});
	}, [memoizeScores]);

	const onClick = (id: string) => {
		navigate(`/admin/credit-history/${id}`);
	};

	return (
		<div className="credit-score-page">
			<h2>Credit Score</h2>
			<Grid container>
				{/* <Grid item sm={12} md={12} >
					
				</Grid> */}
				<Grid item sm={12} md={12}>
					<div className="chart-container">
						<h5>Users Credit Score Status</h5>
						<Box marginLeft={2} mt={2}>
							<Box display="flex" gap="10px" alignItems="center">
								<CircleIcon sx={{ fontSize: '15px', color: '#348E49' }} />
								<Typography variant="subtitle2">Excellent</Typography>
							</Box>

							<Box display="flex" gap="10px" alignItems="center">
								<CircleIcon sx={{ fontSize: '15px', color: '#8BB74A' }} />
								<Typography variant="subtitle2">Very Good</Typography>
							</Box>

							<Box display="flex" gap="10px" alignItems="center">
								<CircleIcon sx={{ fontSize: '15px', color: '#F8D73F' }} />
								<Typography variant="subtitle2">Good</Typography>
							</Box>

							<Box display="flex" gap="10px" alignItems="center">
								<CircleIcon sx={{ fontSize: '15px', color: '#ED9141' }} />
								<Typography variant="subtitle2">Average</Typography>
							</Box>

							<Box display="flex" gap="10px" alignItems="center">
								<CircleIcon sx={{ fontSize: '15px', color: '#AA2E28' }} />
								<Typography variant="subtitle2">Below Average</Typography>
							</Box>
						</Box>
						<div id="chartdiv" style={{ width: '100%', height: '500px' }} />
					</div>
				</Grid>
				<Grid item sm={12} md={12} className="credit-table-grid">
					<h5>Credit Score Users</h5>
					<div className="table-container">
						{isLoading ? (
							<TableContentLoader />
						) : (
							<TableContainer className="cus-table">
								<Table
									sx={{ minWidth: 650 }}
									stickyHeader
									aria-label="sticky table"
								>
									<TableHead>
										<TableRow>
											<TableCell>User Name</TableCell>
											<TableCell>Credit Score</TableCell>
											<TableCell>Last Updated</TableCell>
											{/* <TableCell>Offer Used</TableCell> */}
											<TableCell>History</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{rows?.map((row: any) => {
											return (
												<TableRow
													key={row.id}
													sx={{
														'&:last-child td, &:last-child th': { border: 0 },
													}}
												>
													<TableCell component="th" scope="row">
														{row.userName}
													</TableCell>
													<TableCell
														className="row"
														style={{
															color: getScoreColour(row?.creditScore),
															fontWeight: 700,
														}}
													>
														{row?.creditScore || '-'}
													</TableCell>
													<TableCell>{row?.lastUpdated || '-'}</TableCell>
													{/* <TableCell>{row?.offerUsed || '-'}</TableCell> */}
													<TableCell>
														<TableActionButton
															alt="show-icon"
															className="show"
															icon={eyeIcon}
															onClick={() => onClick(row.id)}
														/>
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						)}
						<TablePagination
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={count}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							className="table-pagination"
						/>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}

export default CreditScore;
